@import '../../../../scss/theme-bootstrap';

.basic-formatter {
  position: relative;
  width: 100%;
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: 120%;
  @include breakpoint($medium-up) {
    background-size: 100%;
  }
}
